import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: Home,
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    // props: {
    //   showButton: true
    // }
  },
  {
    path: '/launcher',
    name: 'Launcher',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Launcher.vue'),
    beforeEnter: checkAuth,
    // props: {
    //   showButton: true
    // }

  },
  // {
  //   path: '/course',
  //   name: 'Course',
  //   component: () => import('../views/Course.vue'),
  // },
  {
    path: '/thanks',
    name: 'Thanks',
    component: () => import(/* webpackChunkName: "thanks" */ '../views/Thanks.vue'),
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function checkAuth(to, from, next) {
  if (!store.state.authorized) {
    next({
      path: '/',
      query: { redirect: to.fullPath }
    })  // they are not authorized, so redirect to login
  } else {
    next() // we are authorized, continue on to the requested route
  }
}


export default router
