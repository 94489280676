<template>
  <!-- <transition name="modal-fade"> -->
  <div class="modal-backdrop1" id="modalToHide">
    <div class="modalx">
      <header class="modal-header1" id="modalTitle">
        <b> Hai finito il TEST?</b>

        <!-- <button type="button" class="btn-close" @click="close">x</button> -->
      </header>

      <section class="modal-body1" id="modalDescription">
        Se hai finito il test Motiva clicca su Continua, altrimenti torna
        indietro per completarlo cliccando su Annulla.
      </section>

      <footer class="modal-footer1">
        <!-- <slot name="footer"> This is the default footer! </slot> -->
        <div class="flexModalFooter">
          <button class="btn_cta centerFlex" @click="endButton">
            <span class="textButt">Continua</span>
            <!-- <span class="plusButt">&#8594;</span> -->
            <!-- &#43;	 -->
          </button>

          <!-- <button type="button" class="btn-blue" @click="endButton">
            Continua
          </button> -->
          <button class="btn_cta_inverted centerFlex" @click="close">
            <span class="textButt">Annulla</span>
          </button>

          <!-- <button type="button" class="btn-red" @click="close">Cancella</button> -->
        </div>
      </footer>
    </div>
  </div>
  <!-- </transition> -->
</template>

<script>
export default {
  name: "Modal",

  mounted() {
    try {
      if (window.location.search != null) {
        const params = new URLSearchParams(window.location.search);
        this.uuid = params.get("uuid");
      }
    } catch (exception) {
      console.error;
    }
  },

  methods: {
    close() {
      // this.$emit("close");
      document.getElementById("modalToHide").style.visibility = "hidden";
    },

    endButton() {
      this.$router.push({
        name: "Thanks",
        query: { uuid: this.uuid },
      });

      document.getElementById("modalToHide").style.visibility = "hidden";
    },
  },
};
</script>

<style>
.modal-backdrop1 {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: purple; */
  /* opacity: 0.5; */
  background-color: rgba(0, 0, 0, 0.5) !important;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modalx {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  opacity: 1;
  flex-direction: column;
}

.modal-header1 {
  padding: 15px;
  /* justify-content: space-between !important; */
  text-align: center;
  letter-spacing: 1.5px;
  position: relative;
  border-bottom: 1px dotted #003da3 !important;
  color: #003da3;
  /* justify-content: space-between; */
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.modal-footer1 {
  border-top: none !important;
  flex-direction: row;
  flex-wrap: unset !important;
  justify-content: unset !important;
}

.flexModalFooter {
  padding: 30px 0px 30px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.modal-footer1 .col-6 {
  max-width: unset !important;
}

.modal-body1 {
  /* position: relative; */
  /* padding: 20px 10px; */
  padding: 30px;
  text-align: center;
  font-size: 20px;
  /* color: #003da3; */
}

.btn-close {
  /* position: absolute;
  top: 0;
  right: 0; */
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #0040ff;
  background: transparent;
  display: flex;
  justify-content: flex-end !important;
  align-content: flex-start !important;
  align-items: flex-start !important;
}
.btn-blue {
  color: white;
  background-image: linear-gradient(135deg, #0040ff, #003da3);
  border: 1px solid #003da3;
  border-radius: 5px;
  padding: 10px;
}
.btn-red {
  color: white;
  background-image: linear-gradient(135deg, red, orange);
  border: 1px solid red;
  border-radius: 5px;
  padding: 10px;
}

/* .modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
} */

/* .modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
} */
</style>
