<template>
  <div id="nav">
    <header class="verticalCenter">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <a
              href="https://www.habacus.com/it"
              target="_blank"
              class="verticalCenter"
            >
              <img src="./assets/logo_nuovo.png" alt="" />
            </a>
          </div>

          <!-- <div class="col-6 horizontalRight verticalCenter">
            <button @click="endButton" class="btnEnd" id="buttonEnd">
              Hai finito il test? Clicca qui.
            </button>
          </div> -->

          <div class="col-6 horizontalRight verticalCenter">
            <button @click="showModal" class="btnEnd" id="buttonEnd">
              Hai finito il test? Clicca qui.
            </button>
          </div>
          <!-- <Modal v-show="isModalVisible" @close="closeModal" /> -->
        </div>
      </div>
    </header>
    <div class="service"></div>

    <div class="borderHeader"></div>
    <Modal v-show="isModalVisible" @close="closeModal" />
  </div>

  <router-view />

  <!-- <FooterH /> -->
</template>




<script>
// import Modal from "./views/Modal.vue";
import Modal from "./components/Modal.vue";
// import FooterH from "./components/FooterH.vue";

export default {
  name: "App",
  components: {
    Modal,
    // FooterH,
    // Modal,
  },
  data: function () {
    return {
      uuid: null,
      isModalVisible: false,
    };
  },

  mounted() {
    try {
      if (window.location.search != null) {
        const params = new URLSearchParams(window.location.search);
        this.uuid = params.get("uuid");
      }
    } catch (exception) {
      console.error;
    }
  },

  methods: {
    // endButton() {
    //   this.$router.push({
    //     name: "Thanks",
    //     query: { uuid: this.uuid },
    //   });
    // },
    showModal() {
      document.getElementById("modalToHide").style.visibility = "visible";

      this.isModalVisible = true;
    },
    // closeModal() {
    //   this.isModalVisible = false;
    // },
  },
};
</script>


<style>
</style>
